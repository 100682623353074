.footer {
  background-color: #333;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: #f7f7f7;
}
@media only screen and (max-width: 56.25em) {
  .footer {
    padding: 8rem 0;
  }
}
.footer__logo-box {
  text-align: center;
  margin-bottom: 8rem;
}
@media only screen and (max-width: 56.25em) {
  .footer__logo-box {
    margin-bottom: 6rem;
  }
}
.footer__logo {
  width: 15rem;
  height: auto;
}
.footer__navigation {
  border-top: 1px solid #777;
  padding-top: 2rem;
  display: inline-block;
}
@media only screen and (max-width: 56.25em) {
  .footer__navigation {
    width: 100%;
    text-align: center;
  }
}
.footer__list {
  list-style: none;
}
.footer__item {
  display: inline-block;
}
.footer__item:not(:last-child) {
  margin-right: 1.5rem;
}
.footer__link:link,
.footer__link:visited {
  color: #f7f7f7;
  background-color: #333;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s;
}
.footer__link:hover,
.footer__link:active {
  transform: scale(1.1);
}
.footer__copyright {
  border-top: 1px solid #777;
  padding-top: 2rem;
  width: 80%;
  float: right;
}
@media only screen and (max-width: 56.25em) {
  .footer__link {
    font-size: 16px;
  }
  .footer__copyright {
    font-size: 16px;
    width: 100%;
    float: none;
    text-align: center;
  }
}
